import * as React from "react"

const VideoAnimation = ({overline, title, video}) => (
  <div className='p40 pt20 flex bt1 pb0 m-wrap'>
    <div className='w-50 m-100 flex flex-wrap'>
      <p class="m0 mb20 book uppercase small w-100">{overline}</p>
      <p className='h4 m0 m-hide'>{title}</p>
    </div>
    <div className='w-50 m-100 m-mt20 pt20'>  
      <video loop={true} playsInline={true} muted={true} autoPlay={true} className='w-100'>
        <source src={video} /> 
      </video>
    </div>  
  </div>
) 
export default VideoAnimation
