import { graphql } from 'gatsby'
import * as React from 'react'
import Layout from '../components/layout'
import ListItems from '../components/listItems'
import MoreProjects from '../components/moreProjects'
import ParallaxBanner from '../components/parallaxBanner'
import SEO from '../components/seo'
import Spacer from '../components/spacer'
import VideoAnimation from '../components/videoAnimation'

class ServicePage extends React.Component {
	render() {
		var data = this.props.data.allDatoCmsServicesPage.nodes[0]
		var projects = this.props.data.projects
		return (
			<Layout>
				<SEO title='Services at MOTIV' />
				<ParallaxBanner image={data.heroImage} text={data.heroText} version='true' title='Services' />
				<div className='bg-black'>
					<Spacer className='m-h-60' />
					<ListItems hideSpacer='true' title={data.scopeTitle} values={data.expertise} />
					<div className='m-spacer' />
					<VideoAnimation overline={data.fullServiceOverline} title={data.sectionTitle} video={data.fullServiceUrl} />
					<MoreProjects projects={data.projects} />
					<div className='p40 pt0 pb0'>
						<div data-scroll className='line--in'></div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default ServicePage

export const query = graphql`
	query ServiceQuery {
		allDatoCmsServicesPage {
			nodes {
				heroText
				heroImage {
					gatsbyImageData
				}
				expertise {
					title
					content
				}
				fullServiceOverline
				sectionTitle
				scopeTitle
				fullServiceUrl
				projects {
					id
					name
					slug
					year
					id
					heroImage {
						gatsbyImageData
					}
				}
			}
		}
	}
`
